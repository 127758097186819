import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "sidebar"
};
const _hoisted_2 = {
  class: "menu"
};
export default {
  __name: 'CmanagerSide',
  props: {
    navIndex: {
      type: Number,
      default: 0
    }
  },
  setup(__props) {
    const FFprops = __props;
    const {
      proxy
    } = getCurrentInstance();
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("dl", _hoisted_2, [_createElementVNode("dd", null, [_createElementVNode("a", {
        href: "/manager/user",
        class: _normalizeClass({
          cs: __props.navIndex == 1
        })
      }, "用户信息", 2)]), _createElementVNode("dd", null, [_createElementVNode("a", {
        href: "/manager/chat",
        class: _normalizeClass({
          cs: __props.navIndex == 2
        })
      }, "对话记录", 2)]), _createElementVNode("dd", null, [_createElementVNode("a", {
        href: "/manager/used",
        class: _normalizeClass({
          cs: __props.navIndex == 3
        })
      }, "TOKEN统计", 2)]), _createElementVNode("dd", null, [_createElementVNode("a", {
        href: "/manager/charts",
        class: _normalizeClass({
          cs: __props.navIndex == 4
        })
      }, "对话时间分布", 2)])])]);
    };
  }
};